import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import type { Client } from "urql";
import { GET_USER } from "./queries";
import { UPDATE_USER, MERGE_USER_SETTINGS } from "./mutations";
import type {
  GetUserQuery,
  GetUserQueryVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  MergeUserSettingsMutation,
  MergeUserSettingsMutationVariables,
  UsersUpdateInput,
} from "@/urql/gql/graphql";

interface User {
  id: string;
  avatar_url: string | null;
  user_id: string;
  emails: string[];
  first_name: string | null;
  last_name: string | null;
  public_repos: number;
  followers: number;
  email_notifications: boolean;
  email_newsletter: boolean;
  banned: boolean;
  settings: Record<string, any>;
}

interface UserState {
  data: User | null;
  status: {
    loading: boolean;
    error: string | null;
    lastFetched: number;
    updating: boolean;
    updateError: string | null;
    mergingSettings: boolean;
    mergeSettingsError: string | null;
  };
}

const initialState: UserState = {
  data: null,
  status: {
    loading: false,
    error: null,
    lastFetched: 0,
    updating: false,
    updateError: null,
    mergingSettings: false,
    mergeSettingsError: null,
  },
};

// Async thunk for fetching user data
export const fetchUserData = createAsyncThunk(
  "user/fetchData",
  async (
    { userId, client }: { userId: string; client: Client },
    { rejectWithValue },
  ) => {
    try {
      const { data, error } = await client
        .query<GetUserQuery, GetUserQueryVariables>(GET_USER, {
          userId,
        })
        .toPromise();

      if (error) throw error;
      if (!data?.usersCollection?.edges?.[0]?.node) {
        throw new Error("User not found");
      }

      const user = data.usersCollection.edges[0].node;
      return {
        ...user,
        settings:
          typeof user.settings === "string"
            ? JSON.parse(user.settings)
            : user.settings,
      };
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : "Failed to fetch user data",
      );
    }
  },
);

// Async thunk for updating user data
export const updateUserData = createAsyncThunk(
  "user/updateData",
  async (
    {
      userId,
      data,
      client,
    }: { userId: string; data: UsersUpdateInput; client: Client },
    { rejectWithValue },
  ) => {
    try {
      const { data: response, error } = await client
        .mutation<UpdateUserMutation, UpdateUserMutationVariables>(
          UPDATE_USER,
          {
            userId,
            userData: data,
          },
        )
        .toPromise();

      if (error) throw error;
      if (!response?.updateusersCollection?.records?.[0]) {
        throw new Error("Failed to update user");
      }

      return response.updateusersCollection.records[0];
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : "Failed to update user data",
      );
    }
  },
);

// Async thunk for merging user settings
export const mergeUserSettings = createAsyncThunk(
  "user/mergeSettings",
  async (
    { id, settings, client }: { id: string; settings: string; client: Client },
    { rejectWithValue },
  ) => {
    try {
      const { data, error } = await client
        .mutation<
          MergeUserSettingsMutation,
          MergeUserSettingsMutationVariables
        >(MERGE_USER_SETTINGS, {
          id,
          settings,
        })
        .toPromise();

      if (error) throw error;
      if (!data?.merge_user_settings) {
        throw new Error("Failed to merge settings");
      }

      return {
        settings:
          typeof data.merge_user_settings === "string"
            ? JSON.parse(data.merge_user_settings)
            : data.merge_user_settings,
      };
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : "Failed to merge settings",
      );
    }
  },
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.data = action.payload;
    },
    clearUser: (state) => {
      state.data = null;
      state.status = initialState.status;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.status.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch user data cases
      .addCase(fetchUserData.pending, (state) => {
        state.status.loading = true;
        state.status.error = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status.loading = false;
        state.status.lastFetched = Date.now();
        state.status.error = null;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status.loading = false;
        state.status.error = action.payload as string;
      })
      // Update user data cases
      .addCase(updateUserData.pending, (state) => {
        state.status.updating = true;
        state.status.updateError = null;
      })
      .addCase(updateUserData.fulfilled, (state, action) => {
        if (state.data) {
          state.data = { ...state.data, ...action.payload };
        }
        state.status.updating = false;
        state.status.updateError = null;
      })
      .addCase(updateUserData.rejected, (state, action) => {
        state.status.updating = false;
        state.status.updateError = action.payload as string;
      })
      // Merge settings cases
      .addCase(mergeUserSettings.pending, (state) => {
        state.status.mergingSettings = true;
        state.status.mergeSettingsError = null;
      })
      .addCase(mergeUserSettings.fulfilled, (state, action) => {
        if (state.data) {
          state.data.settings = action.payload.settings;
        }
        state.status.mergingSettings = false;
        state.status.mergeSettingsError = null;
      })
      .addCase(mergeUserSettings.rejected, (state, action) => {
        state.status.mergingSettings = false;
        state.status.mergeSettingsError = action.payload as string;
      });
  },
});

export const { setUser, clearUser, setError } = userSlice.actions;

// Selectors
export const selectUserData = (state: { user: UserState }) => state.user.data;
export const selectUserStatus = (state: { user: UserState }) =>
  state.user.status;
export const selectUserSettings = (state: { user: UserState }) =>
  state.user.data?.settings;
export const selectIsLoading = (state: { user: UserState }) =>
  state.user.status.loading;
export const selectIsUpdating = (state: { user: UserState }) =>
  state.user.status.updating;
export const selectIsMergingSettings = (state: { user: UserState }) =>
  state.user.status.mergingSettings;
export const selectError = (state: { user: UserState }) =>
  state.user.status.error;
export const selectUpdateError = (state: { user: UserState }) =>
  state.user.status.updateError;
export const selectMergeSettingsError = (state: { user: UserState }) =>
  state.user.status.mergeSettingsError;

export default userSlice;
