"use client";
/**
 * @fileoverview Authentication management module that provides context-based auth state management
 * integrated with Redux store.
 */

import React, { createContext, useContext, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  DecodedToken,
  loadAllPlatformData,
  logout,
  PlatformAccount,
  PrimaryAccount,
  selectAuthError,
  selectAuthLoading,
  selectConnectedAccounts,
  selectConnectedAccountsData,
  selectDecodedToken,
  selectIsAuthenticated,
  selectPrimaryAccount,
  selectRawToken,
} from "./auth.slice";

/**
 * Interface defining the shape of authentication context data
 * @interface
 */
interface AuthContextType {
  /** Flag indicating if user is currently authenticated */
  isAuthenticated: boolean;
  /** Decoded JWT token */
  decodedToken: DecodedToken | null;
  /** Raw JWT token for API calls */
  rawToken: string | null;
  /** Authentication status information */
  status: {
    loading: boolean;
    error: string | null;
  };
  /** Primary account information */
  primaryAccount: PrimaryAccount | null;
  /** Connected platform accounts */
  connectedAccounts: PlatformAccount[];
  /** Function to handle user logout */
  logout: () => void;
}

/**
 * Context for storing authentication state
 * @private
 */
const AuthContext = createContext<AuthContextType | undefined>(undefined);

/**
 * Custom hook for accessing authentication state and platform-specific data
 *
 * This hook provides access to:
 * - Core authentication state (token, isAuthenticated)
 * - Primary account information
 * - Platform-specific account data (GitHub, etc.)
 *
 * Features:
 * - Lazy loading of platform data (Only loads data when actually accessed)
 * - Prevents multiple unnecessary loads
 * - Safe state updates (avoids React render-phase updates)
 *
 * @throws {Error} When used outside of AuthManager context
 * @returns {AuthContextType} Authentication context with lazy-loaded platform data
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  const dispatch = useDispatch();

  // Track if we've already dispatched the load action to prevent multiple loads
  const hasDispatched = useRef(false);

  // Flag to indicate we need to load data (used to defer dispatch outside render phase)
  const needsDispatch = useRef(false);

  if (!context) {
    throw new Error("useAuth must be used within an AuthManager");
  }

  // Effect to handle the actual data loading
  // This runs after render to avoid React warnings about updates during render
  useEffect(() => {
    // Only dispatch if we need data and haven't already dispatched
    if (needsDispatch.current && !hasDispatched.current) {
      hasDispatched.current = true;
      dispatch(loadAllPlatformData());
    }
  });

  // Create a proxy to intercept property access
  // This allows us to lazily load platform data only when it's actually needed
  return new Proxy(context, {
    get(target, prop) {
      // When component tries to access connectedAccountsData
      if (prop === "connectedAccountsData" && !hasDispatched.current) {
        const data = target[prop];
        // Check if we need to load the data
        // - Data array is empty AND
        // - We have connected accounts that need data
        if (data.length === 0 && target.connectedAccounts.length > 0) {
          // Set flag to load data, actual loading happens in useEffect
          needsDispatch.current = true;
        }
      }
      // Return the requested property
      return target[prop];
    },
  });
};

/**
 * Props interface for AuthManager component
 * @interface
 */
interface AuthManagerProps {
  /** Child components to be wrapped by the AuthManager */
  children: React.ReactNode;
}

export const AuthManager: React.FC<AuthManagerProps> = ({ children }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const decodedToken = useSelector(selectDecodedToken);
  const rawToken = useSelector(selectRawToken);
  const error = useSelector(selectAuthError);
  const loading = useSelector(selectAuthLoading);
  const primaryAccount = useSelector(selectPrimaryAccount);
  const connectedAccounts = useSelector(selectConnectedAccounts);
  const connectedAccountsData = useSelector(selectConnectedAccountsData);

  const handleLogout = () => {
    dispatch(logout());
  };

  const value = {
    isAuthenticated,
    decodedToken,
    rawToken,
    status: {
      loading,
      error,
    },
    primaryAccount,
    connectedAccounts,
    connectedAccountsData,
    logout: handleLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { default as authSlice } from "./auth.slice";
