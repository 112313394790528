import { createSlice } from "@reduxjs/toolkit";

// Theme
export enum Theme {
  DARK,
  LIGHT,
}

export interface ThemeSlice {
  theme: Theme;
}

// Initial state
const initialState: ThemeSlice = {
  theme: Theme.LIGHT,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: (state: ThemeSlice) => {
      state.theme = state.theme === Theme.DARK ? Theme.LIGHT : Theme.DARK;

      // Remove both classes first to ensure clean state
      document.body.classList.remove("cds--g10", "cds--g100");

      // Add appropriate class based on theme
      const themeClass = state.theme === Theme.LIGHT ? "cds--g10" : "cds--g100";
      document.body.classList.add(themeClass);

      return state;
    },
  },
});
