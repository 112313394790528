import { useAuth } from "@/apx/AuthManager";
import UnAuthorized from "@/app/unauthorized/page";
import { InlineLoading } from "@carbon/react";

/**
 * AuthRequired - A component that gates content based on authentication state
 *
 * This component controls the rendering of child components based on the current
 * authentication state of the user. It handles various stages of the authentication
 * process including loading, error states, and successful authentication.
 *
 * @param {React.ReactNode} children - The content to render when authentication is successful
 * @param {boolean} [returnEmpty=false] - Controls component behavior when authentication fails:
 *   - When true: Returns null for all non-authenticated states (not authenticated, loading, error)
 *   - When false: Shows appropriate UI for each state (unauthorized page, loading indicator, error)
 *
 * @returns {React.ReactNode} One of the following based on authentication state:
 *   1. Loading state (status.loading === true):
 *      - If returnEmpty is true: null
 *      - If returnEmpty is false: Centered loading indicator
 *
 *   2. Error state (status.error exists):
 *      - If returnEmpty is true: null
 *      - If returnEmpty is false: Throws an error with detailed information
 *
 *   3. Not authenticated (isAuthenticated === false):
 *      - If returnEmpty is true: null
 *      - If returnEmpty is false: UnAuthorized component
 *
 *   4. Authenticated (isAuthenticated === true, no loading/error):
 *      - The children passed to the component
 */

interface AuthRequiredProps {
  children: React.ReactNode;
  returnEmpty?: boolean;
}

export function AuthRequired({
  children,
  returnEmpty = false,
}: AuthRequiredProps) {
  const { isAuthenticated, status } = useAuth();

  if (!isAuthenticated) {
    return returnEmpty ? <></> : <UnAuthorized />;
  }

  if (status.loading) {
    return returnEmpty ? (
      <></>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 48px)",
        }}
      >
        <InlineLoading
          style={{ width: "0px" }}
          description="Loading"
          iconDescription="Loading Page..."
        />
      </div>
    );
  }

  if (status.error) {
    if (returnEmpty) {
      return <></>;
    }

    throw new Error("Authentication failed", {
      cause: {
        title: "Authentication Error",
        description: status.error,
        kind: "custom",
      },
    });
  }

  return <>{children}</>;
}
