import { useSelector } from "react-redux";
import { Theme } from "./Theme.slice";

export const useTheme = () => {
  const theme = useSelector((state: any) => state.theme.theme);
  const isDark = theme === Theme.DARK;
  const isLight = theme === Theme.LIGHT;

  return {
    theme,
    isDark,
    isLight,
    themeName: isDark ? "g100" : ("g10" as "g100" | "g10"),
  };
};
