import { gql } from "urql";

export const GET_USER = gql`
  query GetUser($userId: UUID!) {
    usersCollection(filter: { id: { eq: $userId } }) {
      edges {
        node {
          id
          avatar_url
          settings
          user_id
          emails
          first_name
          last_name
          public_repos
          followers
          email_notifications
          email_newsletter
          banned
        }
      }
    }
  }
`;
