import { gql } from "urql";

export const UPDATE_USER = gql`
  mutation UpdateUser($userId: UUID!, $userData: usersUpdateInput!) {
    updateusersCollection(filter: { id: { eq: $userId } }, set: $userData) {
      affectedCount
      records {
        id
        avatar_url
        user_id
        emails
        first_name
        last_name
        email_notifications
        email_newsletter
      }
    }
  }
`;

export const MERGE_USER_SETTINGS = gql`
  mutation MergeUserSettings($id: UUID!, $settings: JSON!) {
    merge_user_settings(id: $id, settings: $settings)
  }
`;
