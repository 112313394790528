import { Config, Environment } from "./types";

// TODO: ideally, this is hosted centrally and fetched at compile time
export const environmentConfigs: Record<Environment, Config> = {
  test: {
    environment: "test",
    siteUrl: "http://test.codeinput.com",
    api: {
      baseUrl: "https://github.staging.codeinput.com",
      timeout: 30000,
    },
    gateway: {
      baseUrl: "https://gateway.staging.codeinput.com",
      timeout: 30000,
    },
    posthog: {
      enabled: false,
      apiKey: "",
      proxyHost: "",
    },
    platforms: {
      github: {
        settings_url:
          "https://github.com/organizations/CodeInputCorp/settings/apps/codeinputapp-staging",
      },
    },
  },
  development: {
    environment: "development",
    siteUrl: "https://staging.codeinput.com",
    api: {
      baseUrl: "https://github.staging.codeinput.com",
      timeout: 30000,
    },
    gateway: {
      baseUrl: "https://gateway.staging.codeinput.com",
      timeout: 30000,
    },
    posthog: {
      enabled: false,
      apiKey: "phc_G8d02M01AT8TyFGrJWNNpI10z0lMd7Nc8dUDJDvAn1s",
      proxyHost: "https://prx.codeinput.com",
    },
    platforms: {
      github: {
        settings_url:
          "https://github.com/organizations/CodeInputCorp/settings/apps/codeinputapp-staging",
      },
    },
  },
  staging: {
    environment: "staging",
    siteUrl: "https://staging.codeinput.com",
    api: {
      baseUrl: "https://github.staging.codeinput.com",
      timeout: 30000,
    },
    gateway: {
      baseUrl: "https://gateway.staging.codeinput.com",
      timeout: 30000,
    },
    posthog: {
      enabled: false,
      apiKey: "",
      proxyHost: "",
    },
    platforms: {
      github: {
        settings_url:
          "https://github.com/organizations/CodeInputCorp/settings/apps/codeinputapp-staging",
      },
    },
  },
  production: {
    environment: "production",
    siteUrl: "https://codeinput.com",
    api: {
      baseUrl: "https://api.codeinput.com",
      timeout: 30000,
    },
    gateway: {
      baseUrl: "https://gateway.codeinput.com",
      timeout: 30000,
    },
    posthog: {
      enabled: true,
      apiKey: "phc_G8d02M01AT8TyFGrJWNNpI10z0lMd7Nc8dUDJDvAn1s",
      proxyHost: "https://prx.codeinput.com",
    },
    platforms: {
      github: {
        settings_url:
          "https://github.com/organizations/CodeInputCorp/settings/apps/code-input",
      },
    },
  },
};
