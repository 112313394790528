import { Config, Environment } from "./types";
import { environmentConfigs } from "./environments";

/**
 * Determines the current environment based on NODE_ENV
 * @returns Environment - 'development' | 'staging' | 'production'
 * @throws Error if NODE_ENV is not one of the valid environments
 * @internal
 */
export const getEnvironment = (): Environment => {
  const env = process.env.NODE_ENV || "development";

  if (!["development", "staging", "production", "test"].includes(env)) {
    throw new Error(`Invalid environment: ${env}`);
  }
  return env as Environment;
};

/**
 * Global configuration object based on current environment
 *
 * @type {Config}
 */
export const config: Config = environmentConfigs[getEnvironment()];

/**
 * Type-safe configuration getter
 * @param key - Configuration key to retrieve
 * @returns The value for the specified config key
 * @example
 * const apiConfig = getConfig('api')
 * const timeout = getConfig('api').timeout
 */
export const getConfig = <K extends keyof Config>(key: K): Config[K] => {
  return config[key];
};
