/**
 * @file Authentication slice for Redux store managing GitHub user authentication
 * @module authSlice
 */

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { Octokit } from "octokit";

/** Flag to determine if code is running in browser environment */
const isBrowser = typeof window !== "undefined";

/** Supported platform types for connected accounts */
export enum Platform {
  Github = "Github",
  Gitlab = "Gitlab",
  Bitbucket = "Bitbucket",
}

/**
 * Represents a connected external platform account
 * Contains platform-specific authentication and identity information
 */
export interface PlatformAccount {
  /** The platform type (e.g., Github, Gitlab) */
  platform: Platform;
  /** OAuth access token for the platform */
  access_token: string;
  /** Username on the platform */
  username: string;
}

/**
 * Represents the user's primary account information in our system
 * Contains core identity details independent of external platforms
 */
export interface PrimaryAccount {
  /** User's username in our system */
  username: string;
  /** User's email address (optional) */
  email?: string;
  /** User's role in our system (optional) */
  role?: string;
  /** User's permission scope (optional) */
  scope?: string;
}

/**
 * Decoded JWT token structure containing user authentication and identity information
 * Combines standard JWT claims with our custom user data
 */
export interface DecodedToken {
  /** Token expiration timestamp (JWT standard) */
  exp: number;
  /** Token issued at timestamp (JWT standard) */
  iat: number;
  /** Subject identifier - our system's user_id (JWT standard) */
  sub: string;

  /** User's primary account information in our system */
  primary_account: PrimaryAccount;
  /** Array of connected platform accounts */
  connected_accounts: PlatformAccount[];
}

/**
 * @function decodeJwtToken
 * @description Decodes a JWT token string into an object
 * @param token The JWT token string
 * @returns {DecodedToken|null} Decoded token or null if invalid
 */
const decodeJwtToken = (token: string): DecodedToken | null => {
  try {
    const [, payloadBase64] = token.split(".");
    const payload = JSON.parse(atob(payloadBase64));
    return payload as DecodedToken;
  } catch (error) {
    console.error("Failed to decode JWT token:", error);
    return null;
  }
};

/**
 * @function encodeJwtToken
 * @description Encodes a token object into a JWT string
 * @param payload The token object to encode
 * @returns {string|null} Encoded JWT string or null if failed
 */
const encodeJwtToken = (payload: DecodedToken): string | null => {
  try {
    const header = { alg: "none", typ: "JWT" };
    const headerBase64 = btoa(JSON.stringify(header));
    const payloadBase64 = btoa(JSON.stringify(payload));
    return `${headerBase64}.${payloadBase64}.`;
  } catch (error) {
    console.error("Failed to encode JWT token:", error);
    return null;
  }
};

/**
 * @interface AuthState
 * @description Redux state interface for authentication
 */
// TODO: review token/rawToken
export interface AuthState {
  /** Decoded JWT token containing user identity and platform connections */
  decodedToken: DecodedToken | null;
  /** Raw JWT token used for API authentication */
  rawToken: string | null;
  /** Authentication status */
  status: {
    /** Global authentication state */
    isAuthenticated: boolean;
    /** Loading state for authentication operations */
    loading: boolean;
    /** Last error message if any */
    error: string | null;
  };
  /** Extended platform account data */
  connectedAccountsData: Array<{
    /** Maps to PlatformAccount from DecodedToken */
    platform: Platform;
    username: string;
    /** Additional platform-specific user data */
    userData: {
      name: string;
      avatar_url: string;
      email?: string;
    } | null;
    /** Per-account status */
    status: {
      loading: boolean;
      error: string | null;
      lastFetched: number;
    };
  }>;
}

/**
 * @interface CredentialsPayload
 * @description Payload interface for setting user credentials
 */
// export interface CredentialsPayload {
//   /** GitHub authentication token */
//   token: DecodedToken;
//   /** GitHub user information */
//   user: GithubUser;
// }

/**
 * @interface GithubUser
 * @description GitHub user information interface
 */
// export interface GithubUser {
//   /** GitHub username */
//   login: string;
//   /** GitHub user ID */
//   id: number;
//   /** URL to user's avatar */
//   avatar_url: string;
//   /** User's full name */
//   name: string;
//   /** User's email address */
//   email: string;
// }

/**
 * @function loadUserInfo
 * @description Async thunk to load authenticated user information from GitHub
 * @param {string} token - GitHub authentication token
 * @returns {Promise<GithubUser>} User information from GitHub
 */
// export const loadUserInfo = createAsyncThunk(
//   "auth/loadUserInfo",
//   async (token: string, { rejectWithValue }) => {
//     try {
//       const octokit = new Octokit({
//         auth: token,
//       });

//       const { data } = await octokit.rest.users.getAuthenticated();

//       return {
//         login: data.login,
//         id: data.id,
//         avatar_url: data.avatar_url,
//         name: data.name,
//         email: data.email,
//       } as GithubUser;
//     } catch (error) {
//       return rejectWithValue("Failed to load user information");
//     }
//   },
// );

export const loadAllPlatformData = createAsyncThunk(
  "auth/loadAllPlatformData",
  async (_, { getState, dispatch }) => {
    const { auth } = getState() as { auth: AuthState };
    if (!auth.decodedToken?.connected_accounts) return;

    // For now, only dispatch GitHub fetches
    const githubAccounts = auth.decodedToken.connected_accounts.filter(
      (account) => account.platform === Platform.Github,
    );

    // Fire all fetches in parallel
    await Promise.all(
      githubAccounts.map((account) => dispatch(loadGithubUserData(account))),
    );
  },
);

export const loadGithubUserData = createAsyncThunk(
  "auth/loadGithubUserData",
  async (account: PlatformAccount, { rejectWithValue }) => {
    try {
      const octokit = new Octokit({
        auth: account.access_token,
      });

      const { data } = await octokit.rest.users.getAuthenticated();

      return {
        platform: Platform.Github,
        username: account.username,
        userData: {
          name: data.name || account.username,
          avatar_url: data.avatar_url,
          email: data.email || undefined,
        },
      };
    } catch (error) {
      return rejectWithValue(
        `Failed to load GitHub data for ${account.username}`,
      );
    }
  },
);

/**
 * @description Gets both raw and decoded tokens from storage/cookies
 * @returns Object containing both token versions or null if not found
 */
const getInitialTokens = (): {
  rawToken: string | null;
  decodedToken: DecodedToken | null;
} => {
  if (!isBrowser) {
    return { rawToken: null, decodedToken: null }; // SSR
  }

  // Check cookie first
  const cookieToken = Cookies.get("jwt_token");
  if (cookieToken) {
    localStorage.setItem("jwt_token", cookieToken);
  }

  // Get from localStorage (either just stored from cookie or previously stored)
  const rawToken = localStorage.getItem("jwt_token");
  if (!rawToken) {
    return { rawToken: null, decodedToken: null };
  }

  return {
    rawToken,
    decodedToken: decodeJwtToken(rawToken),
  };
};

/**
 * @const initialState
 * @description Initial state for the authentication slice
 */
const initialState: AuthState = (() => {
  const { rawToken, decodedToken } = getInitialTokens();
  return {
    decodedToken,
    rawToken,
    status: {
      isAuthenticated: !!decodedToken,
      loading: false,
      error: null,
    },
    connectedAccountsData: [],
  };
})();

/**
 * @const authSlice
 * @description Redux slice for authentication state management
 */
// const authSlice = createSlice({
//   name: "auth",
//   initialState,
//   reducers: {
//     setCredentials: (state, action: PayloadAction<CredentialsPayload>) => {
//       const { token, user } = action.payload;
//       state.token = token;
//       state.user = user;
//       state.isAuthenticated = true;
//       localStorage.setItem("jwt_token", encodeJwtToken(token));
//     },
//     logout: (state) => {
//       state.token = null;
//       state.user = null;
//       state.isAuthenticated = false;
//       localStorage.removeItem("jwt_token");
//       // remove token from cookie as well
//       Cookies.remove("jwt_token", {
//         path: "/",
//         domain: "codeinput.com",
//         secure: true,
//       });
//     },
//     setError: (state, action: PayloadAction<string>) => {
//       state.error = action.payload;
//       state.loading = false;
//     },
//     setLoading: (state, action: PayloadAction<boolean>) => {
//       state.loading = action.payload;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(loadUserInfo.pending, (state) => {
//         state.loading = true;
//         state.error = null;
//       })
//       .addCase(
//         loadUserInfo.fulfilled,
//         (state, action: PayloadAction<GithubUser>) => {
//           state.loading = false;
//           state.user = action.payload;
//           state.userLoaded = true;
//           state.error = null;
//         },
//       )
//       .addCase(loadUserInfo.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload as string;
//         state.userLoaded = false;
//       });
//   },
// });

interface SetCredentialsPayload {
  rawToken: string;
  decodedToken: DecodedToken;
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<SetCredentialsPayload>) => {
      const { rawToken, decodedToken } = action.payload;
      state.rawToken = rawToken;
      state.decodedToken = decodedToken;
      state.status.isAuthenticated = true;
      localStorage.setItem("jwt_token", rawToken);
    },
    logout: (state) => {
      state.rawToken = null;
      state.decodedToken = null;
      state.status.isAuthenticated = false;
      state.status.error = null;
      localStorage.removeItem("jwt_token");
      Cookies.remove("jwt_token", {
        path: "/",
        domain: "codeinput.com",
        secure: true,
      });
    },
    setError: (state, action: PayloadAction<string>) => {
      state.status.error = action.payload;
      state.status.loading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.status.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadGithubUserData.pending, (state, action) => {
        const accountIndex = state.connectedAccountsData.findIndex(
          (acc) =>
            acc.platform === Platform.Github &&
            acc.username === action.meta.arg.username,
        );

        if (accountIndex === -1) {
          state.connectedAccountsData.push({
            platform: Platform.Github,
            username: action.meta.arg.username,
            userData: null,
            status: {
              loading: true,
              error: null,
              lastFetched: 0,
            },
          });
        } else {
          state.connectedAccountsData[accountIndex].status.loading = true;
          state.connectedAccountsData[accountIndex].status.error = null;
        }
      })
      .addCase(loadGithubUserData.fulfilled, (state, action) => {
        const accountIndex = state.connectedAccountsData.findIndex(
          (acc) =>
            acc.platform === Platform.Github &&
            acc.username === action.payload.username,
        );

        if (accountIndex !== -1) {
          state.connectedAccountsData[accountIndex] = {
            ...state.connectedAccountsData[accountIndex],
            userData: action.payload.userData,
            status: {
              loading: false,
              error: null,
              lastFetched: Date.now(),
            },
          };
        }
      })
      .addCase(loadGithubUserData.rejected, (state, action) => {
        const accountIndex = state.connectedAccountsData.findIndex(
          (acc) =>
            acc.platform === Platform.Github &&
            acc.username === action.meta.arg.username,
        );

        if (accountIndex !== -1) {
          state.connectedAccountsData[accountIndex].status = {
            loading: false,
            error: action.payload as string,
            lastFetched: Date.now(),
          };
        }
      });
  },
});

export const { setCredentials, logout, setError, setLoading } =
  authSlice.actions;

// export const selectCurrentUser = (state: { auth: AuthState }) =>
//   state.auth.user;
// export const selectUserLoaded = (state: { auth: AuthState }) =>
//   state.auth.userLoaded;
// export const selectCurrentToken = (state: { auth: AuthState }) =>
//   state.auth.token;
// export const selectCurrentRawToken = (state: { auth: AuthState }) =>
//   state.auth.rawToken;
// export const selectIsAuthenticated = (state: { auth: AuthState }) =>
//   state.auth.isAuthenticated;
// export const selectAuthError = (state: { auth: AuthState }) => state.auth.error;
// export const selectAuthLoading = (state: { auth: AuthState }) =>
//   state.auth.loading;

/**
 * Basic state selectors
 */
export const selectDecodedToken = (state: { auth: AuthState }) =>
  state.auth.decodedToken;

export const selectRawToken = (state: { auth: AuthState }) =>
  state.auth.rawToken;

export const selectAuthStatus = (state: { auth: AuthState }) =>
  state.auth.status;

/**
 * Derived selectors
 */
export const selectIsAuthenticated = (state: { auth: AuthState }) =>
  state.auth.status.isAuthenticated;

export const selectAuthError = (state: { auth: AuthState }) =>
  state.auth.status.error;

export const selectAuthLoading = (state: { auth: AuthState }) =>
  state.auth.status.loading;

/**
 * User info selectors
 */
export const selectPrimaryAccount = (state: { auth: AuthState }) =>
  state.auth.decodedToken?.primary_account ?? null;

export const selectConnectedAccounts = (state: { auth: AuthState }) =>
  state.auth.decodedToken?.connected_accounts ?? [];

/**
 * Platform-specific selectors
 */
export const selectPlatformAccounts =
  (platform: Platform) => (state: { auth: AuthState }) =>
    selectConnectedAccounts(state).filter(
      (account) => account.platform === platform,
    );

/**
 * Platform data selectors
 */
export const selectConnectedAccountsData = (state: { auth: AuthState }) =>
  state.auth.connectedAccountsData ?? [];

export const selectPlatformAccountsData =
  (platform: Platform) => (state: { auth: AuthState }) =>
    selectConnectedAccountsData(state).filter(
      (account) => account.platform === platform,
    );

export const selectPlatformAccountData =
  (platform: Platform, username: string) => (state: { auth: AuthState }) =>
    selectConnectedAccountsData(state).find(
      (account) =>
        account.platform === platform && account.username === username,
    ) ?? null;

/**
 * Loading state selectors
 */
export const selectPlatformDataLoading = (state: { auth: AuthState }) =>
  selectConnectedAccountsData(state).some((account) => account.status.loading);

export const selectPlatformDataErrors = (state: { auth: AuthState }) =>
  selectConnectedAccountsData(state)
    .filter((account) => account.status.error)
    .map(({ platform, username, status }) => ({
      platform,
      username,
      error: status.error,
    }));

export default authSlice;
