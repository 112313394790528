import { Theme as CarbonTheme, GlobalTheme } from "@carbon/react";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "./useTheme";
import Background from "@/app/components/page/Background";

function ThemeWrapper({ children, theme }: any) {
  const dispatch = useDispatch();
  const { themeName } = useTheme();

  useEffect(() => {
    dispatch({ type: "global/closePanels" });
  }, []); // Empty dependency array means this runs once on mount

  return (
    <>
      <GlobalTheme theme={themeName}>
        <CarbonTheme theme={themeName} style={{ background: "none" }}>
          {children}
        </CarbonTheme>
      </GlobalTheme>
    </>
  );
}

export default ThemeWrapper;
