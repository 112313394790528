"use client";
// TODO: update Login URL here

import { FullPageError } from "@carbon/ibm-products";
import { Button } from "@carbon/react";
import { useEffect } from "react";
import * as Sentry from "@sentry/browser";

// Initialize Sentry
Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,
  beforeSend(event) {
    event.extra = {
      ...event.extra,
      runtime: "browser",
      url: window.location.href,
      userAgent: navigator.userAgent,
    };
    return event;
  },
  integrations: [],
});

export default function Unauthorized() {
  useEffect(() => {
    // Send unauthorized access attempt to Sentry
    Sentry.withScope((scope) => {
      scope.setTag("page", "unauthorized");
      scope.setExtra("accessAttempt", {
        url: window.location.href,
        timestamp: new Date().toISOString(),
      });
      Sentry.captureMessage("Unauthorized access attempt", "warning");
    });
  }, []);

  return (
    <FullPageError
      description="You need to be logged in to access this page. Please sign in with your GitHub account to continue."
      kind="403"
      label="Access Denied"
      title="Authentication Required"
    >
      <Button onClick={() => (window.location.href = "/api/auth/login")}>
        Sign in with GitHub
      </Button>
    </FullPageError>
  );
}
