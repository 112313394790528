import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useClient } from "urql";
import type { UsersUpdateInput } from "@/urql/gql/graphql";
import {
  fetchUserData,
  updateUserData,
  mergeUserSettings,
  selectUserData,
  selectUserStatus,
  selectUserSettings,
  selectIsLoading,
  selectIsUpdating,
  selectIsMergingSettings,
  selectError,
  selectUpdateError,
  selectMergeSettingsError,
} from "./user.slice";

export const useUser = (userId: string) => {
  const dispatch = useDispatch();
  const client = useClient();

  // Selectors
  const user = useSelector(selectUserData);
  const status = useSelector(selectUserStatus);
  const settings = useSelector(selectUserSettings);
  const isLoading = useSelector(selectIsLoading);
  const isUpdating = useSelector(selectIsUpdating);
  const isMergingSettings = useSelector(selectIsMergingSettings);
  const error = useSelector(selectError);
  const updateError = useSelector(selectUpdateError);
  const mergeSettingsError = useSelector(selectMergeSettingsError);

  // Actions
  const fetchUser = useCallback(() => {
    dispatch(fetchUserData({ userId, client }));
  }, [dispatch, userId, client]);

  const updateUser = useCallback(
    (data: UsersUpdateInput) => {
      dispatch(updateUserData({ userId, data, client }));
    },
    [dispatch, userId, client],
  );

  const mergeSettings = useCallback(
    (settings: Record<string, any>) => {
      if (!user?.id) return;
      // Stringify the settings object before passing to the mutation
      const stringifiedSettings = JSON.stringify(settings);
      dispatch(
        mergeUserSettings({
          id: user.id,
          settings: stringifiedSettings,
          client,
        }),
      );
    },
    [dispatch, user?.id, client],
  );

  return {
    user,
    settings,
    loaded: !!user,
    isLoading,
    status,
    error,
    updateUser,
    fetchUser,
    isUpdating,
    updateError,
    mergeSettings,
    isMergingSettings,
    mergeSettingsError,
  };
};

// re-exports
export { default as userSlice } from "./user.slice";
